import ContentContainer from "../elements/ContentContainer";
import ContentHeader from "../elements/ContentHeader";
import ContentImage from "../elements/ContentImage";
import ContentParagraph from "../elements/ContentParagraph";
import ContentLink from "../elements/ContentLink";

function Index() {
  return (
    <ContentContainer title="Home">
      <ContentImage src="sqblack2.png">
        <small>Art by <ContentLink href="https://dragonmelde.com/links/">DragonMelde</ContentLink></small>
      </ContentImage>
      <ContentHeader>Welcome...</ContentHeader>
      <ContentParagraph>...to the internet home of Squam, a musician, lizard, and user of computers.</ContentParagraph>
    </ContentContainer>
  );
}

export default Index;
