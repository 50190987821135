import ContentContainer from "../elements/ContentContainer";
import ContentHeader from "../elements/ContentHeader";
import ContentImage from "../elements/ContentImage";
import ContentLink from "../elements/ContentLink";
import ContentParagraph from "../elements/ContentParagraph";

function About() {
  return (
    <ContentContainer title="About">
      <ContentImage src="coffeeblack.png">
        <small>Art by <ContentLink href="https://www.fiverr.com/viper_sniper">viper_sniper</ContentLink></small>
      </ContentImage>
      <ContentHeader>About me</ContentHeader>
      <ContentParagraph>I've lived in Texas my whole life. I'm married to a very charming person who usually goes by <strong>FigEnabler</strong> online.</ContentParagraph>
      <ContentParagraph>I make electronic music.</ContentParagraph>
      <ContentParagraph>I enjoy computers, and I currently work as a software engineer. I made this website.</ContentParagraph>
      <ContentParagraph>I've been loosely involved with the furry fandom since about 2012, but up until about 2017 I was just a lurker. My fursona is a lizard (no specific species). The name "Squam" comes from the <ContentLink href="https://en.wikipedia.org/wiki/Squamata">order of reptiles that contains lizards and snakes</ContentLink>.</ContentParagraph>
    </ContentContainer>
  );
}

export default About;
