import { NavLink } from "react-router-dom";

function Nav() {
  // https://flowbite.com/docs/components/navbar/
  let linkClasses = "block md:bg-navy hover:bg-black p-2 md:p-5 transition-all"
  let linkActiveClasses = "block md:bg-navy hover:bg-black p-2 md:p-5 bold text-white"

  return (
    <nav className="w-full bg-navy border-gray-200 px-2 py-2.5">
      <div className="block items-center">
        <ul className="flex flex-col p-4 mt-4 border border-gray-100 bg-navy md:flex-row md:space-x-8 border-0 items-center">
          <li>
            <NavLink to="/" className={({ isActive }) => isActive ? linkActiveClasses : linkClasses}>Home</NavLink>
          </li>
          <li>
            <NavLink to="about" className={({ isActive }) => isActive ? linkActiveClasses : linkClasses}>About</NavLink>
          </li>
          <li>
            <NavLink to="music" className={({ isActive }) => isActive ? linkActiveClasses : linkClasses}>Music</NavLink>
          </li>
          <li>
            <NavLink to="elsewhere" className={({ isActive }) => isActive ? linkActiveClasses : linkClasses}>Find me elsewhere</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
