import ContentContainer from "../elements/ContentContainer";
import ContentHeader from "../elements/ContentHeader";
import ContentParagraph from "../elements/ContentParagraph";

function NotFound() {
  return (
    <ContentContainer title="404">
      <ContentHeader>404</ContentHeader>
      <ContentParagraph>That page doesn't exist 🤔</ContentParagraph>
    </ContentContainer>
  );
}

export default NotFound;
