import { Routes, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';

import Nav from './components/elements/Nav';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Elsewhere from './components/pages/Elsewhere';
import Music from './components/pages/Music';

import './App.css';
import NotFound from './components/pages/NotFound';

function App() {
  return (
    <div className="App font-cousine text-seafoam">
      <Nav />
      <AnimatePresence mode="wait">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='music' element={<Music />} />
          <Route path='elsewhere' element={<Elsewhere />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
