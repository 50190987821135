import ContentContainer from "../elements/ContentContainer";
import ContentHeader from "../elements/ContentHeader";
import ContentImage from "../elements/ContentImage";
import ContentLink from "../elements/ContentLink";
import ContentParagraph from "../elements/ContentParagraph";

function Music() {
  return (
    <ContentContainer title="Music">
      <ContentImage src="danceblack.png">
        <small>Art by <ContentLink href="https://twitter.com/bellaben_O">bellaben_O</ContentLink></small>
      </ContentImage>
      <ContentHeader>Music</ContentHeader>
      <ContentParagraph>Electronic music production is my main creative outlet in life. Since 2011 I've been searching for methods to make my computer beep and boop in satisfying ways, and I don't envision myself stopping anytime soon.</ContentParagraph>
      <ContentParagraph>Check out my work on any of these streaming platforms:</ContentParagraph>
      <ContentParagraph>
        <span className="">
          <ul className="">
            <li><ContentLink href="https://open.spotify.com/artist/6zbH1edNWucxmcgAvcNHUL?si=23657a6826204ca2"><i className="fa-brands fa-spotify px-5"></i>Spotify</ContentLink></li>
            <li><ContentLink href="https://music.apple.com/us/artist/squam/1705762515"><i className="fa-solid fa-music px-5"></i>Apple Music</ContentLink></li>
            <li><ContentLink href="https://www.youtube.com/channel/UCOUYPWAUqxHlmEuZPwcSVZw"><i className="fa-brands fa-youtube px-5"></i>YouTube Music</ContentLink></li>
            <li><ContentLink href="https://soundcloud.com/squam0"><i className="fa-brands fa-soundcloud px-5"></i>Soundcloud</ContentLink></li>
            <li><ContentLink href="https://squam.bandcamp.com"><i className="fa-brands fa-bandcamp px-5"></i>Bandcamp</ContentLink></li>
          </ul>
        </span>
      </ContentParagraph>
    </ContentContainer>
  );
}

export default Music;
